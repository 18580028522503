exports.components = {
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-1-js": () => import("./../../../src/pages/product1.js" /* webpackChunkName: "component---src-pages-product-1-js" */),
  "component---src-pages-product-2-js": () => import("./../../../src/pages/product2.js" /* webpackChunkName: "component---src-pages-product-2-js" */),
  "component---src-pages-product-3-js": () => import("./../../../src/pages/product3.js" /* webpackChunkName: "component---src-pages-product-3-js" */),
  "component---src-pages-product-4-js": () => import("./../../../src/pages/product4.js" /* webpackChunkName: "component---src-pages-product-4-js" */),
  "component---src-pages-product-5-js": () => import("./../../../src/pages/product5.js" /* webpackChunkName: "component---src-pages-product-5-js" */),
  "component---src-pages-product-6-js": () => import("./../../../src/pages/product6.js" /* webpackChunkName: "component---src-pages-product-6-js" */)
}

